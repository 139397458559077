<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <div class="form">
                <input type="text" class="create-field" placeholder="create a new document" v-model='title'>
                <button class="formatting-button add">
                    <span class="material-icons-outlined">
                    add
                    </span>
                </button>
            </div>
            
        </form>
    </div>
    
</template>

<script>
import { ref } from 'vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'

export default {
    setup() {
        const { error, addDoc } = useCollection('documents')
        
        const { user } = getUser()
        const router = useRouter()

        const title = ref('')

        const handleSubmit = async () => {
            const res = await addDoc({
                title: title.value,
                content: '',
                createdBy: user.value.uid,
                createdAt: firebase.database.ServerValue.TIMESTAMP,
                tags: [],
                pinned: false,
                archived: false
            }).then( (doc) => {
                title.value = ''
                router.push({name: 'Doc', params: { doc_id: doc.id} })
            })
            if(error) {
                console.log(error)
            }
        }
        return { title, handleSubmit }
    }
}
</script>

<style>

</style>