import { ref } from '@vue/reactivity'
import { auth } from '@/firebase/config'
import { store } from '../store'

const error = ref(null)

const login = async (email, password) => {
    error.value = null

    try{
        const res = await auth.signInWithEmailAndPassword(email, password)
        store.dispatch('setUser', res.user.uid)
        error.value = null
        // console.log(res)
        return res
    } catch(err) {
        console.log(err.value)
        error.value = 'Incorrect login credentials'
    }
}

const useLogin = () => {
    return { error, login }
}

export default useLogin