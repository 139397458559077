<template>
  <section class="hero">
    <div class="hero-container">
        <h1 class="example1-text"> A text editor that helps you tweak your&nbsp;</h1>
        <h1 class="example1-text variant" @click.stop="toggleVariantList">{{ exampleOneText }}</h1>
        <h1 class="example1-text">.</h1> 
        <!--Can add this to the below div to have the Variant List close v-click-outside="toggleVariantList" -->
        <div id="example-1-variants" v-if="showExample1Variants">
          <ul>
            <div class="inputContainer">
              <input id="example1Input" type="text" class="inputField" v-model="tempVariant" @keyup.enter="addVariant" placeholder="try your own ideas here">
              <button class="input-button" type="submit" @click.stop="toggleVariantButtonClick">
                <span class="material-icons-outlined">
                add
                </span>
              </button>
            </div>
            <li v-for="item in exampleOneArray" :key="item.id" @click.stop="setExampleOneText(item.string)">
            {{ item.string }}
            </li>
          </ul>
        </div>
      <!-- <img class="hero-logo" alt="Tweak logo" src="@/assets/tweak-logo-light-theme.svg"> -->
    </div>
    
    <div class="break"></div>
    <div class="container example">
      <div class="example1-text-container">
        <h3 class="">“The only kind of writing is rewriting.”  ― Ernest Hemingway</h3>
       
        <!-- Can add this to toggle Variant List from clicking variant @click.stop="toggleVariantList" -->
        
        <!-- <p class="hint" v-if="!showExample1Variants"> &lt; click</p> -->
      </div>
      
    </div>
  </section>
</template>

<script>
import vClickOutside from 'click-outside-vue3'


export default {
  directives: {
      clickOutside: vClickOutside.directive
  },
  data() {
    return {
      logo: '@/assets/tweak-logo-light-theme.svg',
      exampleOneText: 'text',
      exampleOneArray: [
          { id: 1, string: 'text'},
          { id: 2, string: 'emails'},
          { id: 3, string: 'papers'},
          { id: 4, string: 'blogs'},
          { id: 5, string: 'website headings'}
      ],
      showExample1Variants: false,
      tempVariant: '',
      tempVariantButtonClicked: false
    }
  },
   methods: {
      setExampleOneText(string) {
      this.exampleOneText = string
      },
      toggleVariantList() {
      this.showExample1Variants = !this.showExample1Variants
      },
      addVariant(e) {
          var newVariant = {id: this.exampleOneArray.length + 1, string: this.tempVariant}
          
          console.log(newVariant)
          console.log(this.tempVariantButtonClicked)
          if (e.key === 'Enter' && this.tempVariant){
              if (!this.exampleOneArray.includes(this.tempVariant)) {
                  this.exampleOneArray.push(newVariant)
              }
              this.tempVariant = ''
          }
          else if (this.tempVariantButtonClicked && this.tempVariant) {
            if (!this.exampleOneArray.includes(this.tempVariant)) {
                  this.exampleOneArray.push(newVariant)
              }
              this.tempVariant = ''
              this.tempVariantButtonClicked = false
              document.getElementById("example1Input").focus()
          }
      },
      closeVariantList() {
        console.log("closeVariantList function ran")
        this.showExample1Variants = false;
        console.log(this.showExample1Variants);
      },
      toggleVariantButtonClick(e){
        this.tempVariantButtonClicked = true;
        this.addVariant(e);
      }
  }
}
</script>

<style>

</style>