// import Vue from 'vue'
import firebase from  'firebase'
import 'firebase/firebase-auth'
import 'firebase/firestore'
import 'firebase/auth'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
    apiKey: "AIzaSyAJjXW3CGbrTCsNftfoRnFVo3mx3xz3LaM",
    authDomain: "tweak-your-text.firebaseapp.com",
    projectId: "tweak-your-text",
    storageBucket: "tweak-your-text.appspot.com",
    messagingSenderId: "544919972680",
    appId: "1:544919972680:web:9c3ebadef88cf1fd0e217d",
    measurementId: "G-WX2NJJ7W17"
  };
  
  //intialize firebase & firestore service
  const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()

  //initialize firebase authentication
  const auth = firebase.auth()

  //collection references
  const usersCollection = db.collection('users')
  const documentsCollection = db.collection('documents')
  const variantControllersCollection = db.collection('variant-controllers')

  const { Timestamp, GeoPoint } = firebase.firestore

  const ui = new firebaseui.auth.AuthUI(firebase.auth())
  
  // Vue.prototype.$firebase = firebase
  // Vue.prototype.$firebaseui = ui

  export { 
    db,
    auth,
    usersCollection,
    documentsCollection,
    variantControllersCollection,
    Timestamp,
    GeoPoint }