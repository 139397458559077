import { createStore } from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import * as fb from '@/firebase/config'




export const store = createStore({
    state: {
        user: '',
        documents: [],
        variantControllers: [],
        theme: 'theme-light',
    },
    getters: {
        user: state => { 
            return state.user
        },
        documents: state => { 
            return state.documents 
        },
        document: (state) => (id) => {
            return state.documents.find(doc => doc.id === id)
        },
        variantControllers: (state) => (parentId) => {
            return state.variantControllers.filter((controller) => {
            // Controllers will make it through the filter if their parentSheet property matches the passed in id that is pulled from the URL params    
                return controller.parentDocument == parentId
            })
        },
        theme: state => {
            return state.theme
        },
    },
    mutations: {
        SET_USER: (state, user) => (state.user = user),
        UNBIND_USER: (state) => (state.user = ''),
        BIND_DOCUMENTS: (state, user) => (
            fb.documentsCollection.where("createdBy", "==", user).onSnapshot(snapshot => {
                let documentsArray = []            
                snapshot.forEach(doc => {
                    let document = doc.data()
                    document.id = doc.id
                    documentsArray.push(document)
                })
                state.documents = documentsArray
                // console.log(store.state.documents)
            })
        ),
        UNBIND_DOCUMENTS: (state) => (
            state.documents = []),
        CHANGE_THEME: (state, theme) => (state.theme = theme),
        ...vuexfireMutations
    },

    actions: {
        setUser: ({ commit }, user) => commit('SET_USER', user),
        unbindUser: ({ commit }) => commit('UNBIND_USER'),
        bindDocuments: ({ commit }, user) => commit('BIND_DOCUMENTS', user),
        unbindDocuments: ({ commit }) => commit('UNBIND_DOCUMENTS'),       
        changeTheme: ({ commit }, theme) => commit('CHANGE_THEME', theme)
    }
})

// bindDocuments: firestoreAction(({ bindFirestoreRef }) => {
//     return bindFirestoreRef('documents', db.collection('documents'), {wait: true})
// }),
// bindVariantControllers: firestoreAction(({ bindFirestoreRef }) => {
//     return bindFirestoreRef('variantControllers', db.collection('variant-controllers'))
// }),

