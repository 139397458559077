import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import EditDocument from '../views/EditDocument.vue';
var auth = require('../firebase/config').auth;
// import { store } from '../store/index'
//authentication guard
var requireAuth = function (to, from, next) {
    var user = auth.currentUser;
    if (!user) {
        next({ name: 'Home' });
    }
    else {
        next();
    }
};
var routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: requireAuth
    },
    {
        path: '/document/:id',
        name: 'Document',
        component: EditDocument,
        beforeEnter: requireAuth,
        props: true
    },
];
var router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
});
export default router;
