<template>
  <div class="navbar">
    <nav class="nav-body">
      <!-- If there isn't a user, logo doesn't need to navigate anywhere -->
      <div v-if="!user" class="nav-logo" alt="Tweak logo" src="../assets/tweak-logo-light-theme.svg" @click.self="goHome"></div>
      <div v-if="user" class="nav-logo hover" alt="Tweak logo" src="../assets/tweak-logo-light-theme.svg" @click.self="enterDashboard"></div>
      
      <div class="multi-button-container">
        

        <!-- <div class="nav-button-container" v-if="!user">
          <span class="nav-button sign-up">Go Premium</span>
          <span class="nav-button login" @click="showModal = true">Login</span>
        </div> -->
        <div class="nav-button-container" v-if="!user && isLogin || !user && isHome">
          <router-link :to="{ name: 'Signup' }" @click.self="test"><span class="nav-button--text signup">Sign up</span></router-link>
        </div>

        <div class="nav-button-container" v-if="!user && isSignUp || !user && isHome">
          <router-link :to="{ name: 'Login' }"><span class="nav-button--text login">Log in</span></router-link>
        </div>

        <div class="nav-button-container" v-if="user">
          <router-link :to="{ name: 'Dashboard' }"><span class="nav-button--text">Dashboard</span></router-link>
          <span class="nav-button--text login" @click="logout">Log Out</span>
        </div>

        <div class="nav-button-container">
            <span v-if="theme === 'theme-light'" @click="changeTheme" class="material-icons-outlined moon nav-button">
              nightlight
            </span>
            <span v-if="theme === 'theme-dark'" @click="changeTheme" class="material-icons-outlined sun nav-button">
              wb_sunny
            </span>
        </div>        
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { mapGetters } from 'vuex'
import getUser from '../composables/getUser'
import useLogout from '../composables/useLogout'
import { useRouter } from 'vue-router'
import { store } from '../store/index'

export default {
  computed: {
    isHome() {
      return this.$route.name == 'Home'
    },
    isLogin() {
      return this.$route.name == 'Login'
    },
    isSignUp() {
      return this.$route.name == 'Signup'
    },
    ...mapGetters([
      'theme'
    ])
  },
  setup() {
    const onSignupView = ref(false)
    const onLoginView = ref(true)

    const { user } = getUser()
    const { logout, error } = useLogout()

    const router = useRouter() 
    
    watch(user,  () => {
      if (!user.value) {
        router.push({ name: 'Home' })
      }
    })

    const enterDashboard = () => {
      router.push({ name: 'Dashboard' })
    }

    const goHome = () => {
      router.push({ name: 'Home' })
    }

    const test = () => {
      console.log('test function ran')
    }

    const changeTheme = () => {
      // If the store theme is the dark theme change it to the light theme, if not change it to the dark theme
      if (store.state.theme == 'theme-dark') {
        document.querySelector('#app').classList = ['theme-light']
        store.dispatch('changeTheme', 'theme-light')
        console.log('changed to theme-light')
      }
      else {
        document.querySelector('#app').classList = ['theme-dark']
        store.dispatch('changeTheme', 'theme-dark')
        console.log('changed to theme-dark')
      }
    }

    return {
      onSignupView,
      onLoginView,
      user,
      enterDashboard, 
      goHome, 
      logout,
      changeTheme,
      test
    }
  }
}
</script>

<style>


</style>