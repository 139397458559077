<template>
   <Login-Form @login="enterDashboard"></Login-Form>
</template>

<script>
import LoginForm from '../components/Home/LoginForm'
import { useRouter } from 'vue-router'

export default {
    components: { LoginForm },
    setup() {
        const router = useRouter()

        const enterDashboard = () => {
            router.push({ name: 'Dashboard' })}
        
        return { enterDashboard }
    }
}
</script>

<style>

</style>