<template>
   <Signup-Form></Signup-Form>
</template>

<script>
import SignupForm from '../components/Home/SignupForm'

export default {
    components: { SignupForm },
}
</script>

<style>

</style>