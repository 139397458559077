// 
<template>
  <body>
    <div class="background"></div>
    <HeroSection />      
    <Tiptap />
    
    
  </body>
</template>

<script>
// @ is an alias to /src
import HeroSection from '../components/Home/HeroSection.vue'
import Example1Section from '../components/Home/Example1Section.vue'
import Tiptap from '../components/Home/Tiptap-4.vue'
export default {
  name: 'Home',
  components: { HeroSection, Example1Section, Tiptap },
}
</script>

<style>
.testing {
  width: 100px;
  height:100px;
  background-color: orange;
}
</style>
