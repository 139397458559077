import { ref } from 'vue'
import { auth } from '../firebase/config'
import { useRouter } from 'vue-router'
import { store } from '../store'
 
const error = ref(null)

const logout = async () => {
    error.value = null

    try {
        await auth.signOut()
        store.dispatch('unbindUser')
        store.dispatch('unbindDocuments')
        auth.unsubscribe()
        
    } catch(err) {
        error.value = err.message
    }
}

const useLogout = () => {
    return { logout, error }
}

export default useLogout