import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
// const router = require('./router')
var store = require('./store/index').store;
// import { store } from './store/index'
//styles
import './assets/main.css';
//import firebase auth service
var auth = require('./firebase/config').auth;
// import { auth } from './firebase/config'
var app;
auth.onAuthStateChanged(function () {
    if (!app) {
        app = createApp(App);
        app.use(store);
        app.use(router);
        app.mount('#app');
    }
});
