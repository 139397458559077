import { Node, mergeAttributes } from '@tiptap/core';
export var TweakableText = Node.create({
    name: 'tweakableText',
    defaultOptions: {
        HTMLAttributes: {}
    },
    inline: true,
    group: "inline",
    content: 'inline*',
    // addAttributes() {
    //     return {
    //         class: {
    //             default: 'tweakableText',
    //         }
    //     }
    // },
    parseHTML: function () {
        return [
            {
                tag: 'span[tweakable-text]'
            }
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    addCommands: function () {
        return {
            setTweakableText: function () { return function (_a) {
                var commands = _a.commands;
                console.log('setTweakableText ran');
                return commands.setNode('tweakableText');
            }; }
        };
    }
});
