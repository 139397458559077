<template>
    <div class="backdrop" @click.self="() => $emit('closeModal')"></div>
    <div class="form-container--login">
            <h1 class="form_heading">Sign up</h1>
            <form @submit.prevent="handleSubmit">
            <input type="name" required class="input--modal" placeholder="name" v-model="displayName">
            <input type="email" required autocomplete="username" class="input--modal" placeholder="email" v-model="email">
            <input type="password" required autocomplete="new-password" class="input--modal" placeholder="password" v-model="password">
            <div class="error">{{ error }}</div>
            <div class="multi-button-container center">
                <button class="nav-button--text cta">Submit</button>
                <!-- <span class="form--subtext">Need to <span class="form--subtext cta" @click="goToLogin">login?</span></span> -->
            </div>
        </form>
        
        </div>
</template>

<script>
import { ref } from 'vue'
import useSignup from '@/composables/useSignup'
import { useRouter } from 'vue-router'

export default {
    setup(props, context) {
        const { error, signup } = useSignup()

        const router = useRouter()

        //refs
        const displayName = ref('')
        const email = ref('')
        const password = ref('')

        const handleSubmit = async() => {
            await signup(email.value, password.value, displayName.value)
            if (!error.value) {
                context.emit('signup')
            }
        }

        const goToLogin = () => {
            router.push({ name: 'Login' })
        }
        
        return { displayName, email, password, handleSubmit, error, goToLogin }
    }
}
</script>

<style>

</style>