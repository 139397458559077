<template>
  <head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
      rel="stylesheet">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
  </head>
  <div>
    <Navbar/>
  </div>
  <router-view/>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { onMounted } from 'vue'
import { store } from './store/index'

export default ({
  name: 'App',
  components: { Navbar },
  setup() {
    onMounted(() => {
      changeTheme()
    })

    const changeTheme = () => {
      if (store.state.theme == 'theme-dark') {
        document.querySelector('#app').classList = ['theme-light']
        store.dispatch('changeTheme', 'theme-light')
        console.log('changed to theme-light')
      }
      else {
        document.querySelector('#app').classList = ['theme-dark']
        store.dispatch('changeTheme', 'theme-dark')
        console.log('changed to theme-dark')
      }
    }

    return { changeTheme }
  },
})
</script>


<style>
</style>
