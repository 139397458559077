<template>
<!-- Editor Buttons --> 
    <div class="container">
        <button class="formatting-button" @click="editor.chain().focus().setTweakableText().run()">
        <span class="material-icons-outlined">
            format_clear
        </span>
    </button>
    </div>
    
<!-- TipTap Editor -->
    <div class="editorDiv" v-if="dataRetrieved">
        <button class="btn_tag" v-for="tag in currentDocument.tags" @click.prevent="deleteTag(doc.id, tag)">{{ tag }}
            <span class="material-icons-outlined">
                clear
            </span>
        </button>
        <button class="btn_tag--add" @click.prevent="">+
            <form class="form--addTag" @submit.prevent="addTag(doc.id)">
              <div class="form--addTag">
                <input type="text" class="input--tag" v-model="newTag" />
              </div>
            </form>
        </button>
        <h1 v-if="currentDocument" class="document-title">{{ currentDocument.title }}</h1>
        <editor-content :editor="editor"/>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { store } from '../store/index'
import { ref } from 'vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { TweakableText } from '../components/TweakableText.ts'
import { auth, documentsCollection } from '../firebase/config'

export default {
    name: 'EditDocument',
    components: {
        EditorContent
    },

    setup(){
        const currentDocument = ref({})
        const documentRef = ref(null)
        const autoSaveTimer = ref(null)
        const dataRetrieved = ref(false)

        //Set up route to be able to grab params from url
        const route = useRoute()

        //Set up editor
        const editor = useEditor({
            content: 'old content',
            extensions: [
                StarterKit,
                TweakableText
            ],
            //Focus after initialization
            autofocus: true,
            onCreate({ editor }) {
                // The editor is ready.
                editor.commands.setContent(currentDocument.value.content)
            },
            onUpdate({ editor }) {
                var editorContent = editor.getHTML()
                autoSave(editorContent)
            }
        })

        //Get Document data from store
        const getCurrentDocument = () => {
            var documentId = route.params.id
            var document = store.getters.document(documentId)

            //Set up a document reference
            documentRef.value = documentsCollection.doc(documentId)

            //If the document is in the store set currentDocument data to the document data
            if(document){
                currentDocument.value = document
                dataRetrieved.value = true
                console.log('document has value')
            } else {
                console.log('document is undefined')
                //If undefined, getUser, getDocuments to store, and set currentDocument.value to the getter data
                getUser()
                getDocuments()

                setTimeout(() => {
                    document = store.getters.document(documentId)

                    //Set current document initial value
                    console.log('setting currentDocument.value')
                    currentDocument.value = document
                    dataRetrieved.value = true
                    setEditorContent()
                }, 1000)  
            }
        }

        const getUser = () => {
            // Get currentUser from Firebase auth
            const userID = auth.currentUser.uid

            // Set userID when coming to Edit Document so if the user reloads the store data is repopulated with documents data
            store.dispatch('setUser', userID)
        }
        const getDocuments = () => {
            // Bind to Firebase snapshots by calling Vuex action
            console.log('getDocuments ran')
            store.dispatch('bindDocuments', store.state.user)
        }

        getCurrentDocument()

        

        const autoSave = (newContent) => {
            clearTimeout(autoSaveTimer.value)
            autoSaveTimer.value = setTimeout(() => {
                saveSheet(newContent)
            }, 3000)
            
            console.log('autoSave ran')
            
        }

        const saveSheet = (newContent) => {
            documentRef.value.update({content: newContent})
            autoSaveTimer.value = null
        }      
        
        const setEditorContent = () => {
            console.log('nothing')
        }

    return {
        currentDocument,
        documentRef,
        dataRetrieved,
        setEditorContent,
        getCurrentDocument,
        getUser,
        getDocuments,
        editor,
        autoSaveTimer,
        autoSave
    }
    }

    // name: variant
    // group: inline
    // inline: true
    // (content : 'inline*')
}
</script>

<style>

</style>