// 
<template>
  <section class="example1">
      <div class="container example">
        <div class="example1-text-container">
          <h2 class="example1-text">tweak is a writing tool that helps you tweak your&nbsp;</h2> 
          <h2 class="example1-text variant" @click.stop="toggleVariantList">{{ exampleOneText }}</h2>
          <p class="hint" v-if="!showExample1Variants"> &lt; click</p>
        </div>
        <div id="example-1-variants" v-if="showExample1Variants" v-click-outside="toggleVariantList">
          <ul>
            <input type="text" v-model="tempVariant" @keyup.enter="addVariant" placeholder="add more variants" @click.stop="">
            <li v-for="item in exampleOneArray" :key="item.id" @click.stop="setExampleOneText(item.string)">
            {{ item.string }}
            </li>
          </ul>
        </div>
      </div>
    </section>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
    directives: {
      clickOutside: vClickOutside.directive
    },
  data (){
      return {
          exampleOneText: 'text.',
          exampleOneArray: [
              { id: 1, string: 'text.'},
              { id: 2, string: 'emails.'},
              { id: 3, string: 'papers.'},
              { id: 4, string: 'blogs.'},
              { id: 5, string: 'website copy.'}
          ],
          showExample1Variants: false,
          tempVariant: ''
      }
  },
  methods: {
      setExampleOneText(string) {
      this.exampleOneText = string
      },
      toggleVariantList() {
      this.showExample1Variants = !this.showExample1Variants
      },
      addVariant(e) {
          var newVariant = {id: this.exampleOneArray.length + 1, string: this.tempVariant}
          if (e.key === 'Enter' && this.tempVariant){
              if (!this.exampleOneArray.includes(this.tempVariant)) {
                  this.exampleOneArray.push(newVariant)
              }
              this.tempVariant = ''
          }
      },
      closeVariantList() {
        console.log("closeVariantList function ran")
        this.showExample1Variants = false;
        console.log(this.showExample1Variants);
      }
  }
}
</script>

<style>

</style>