<template>
  <!-- <h1>{{ documents }}</h1> -->
  <div class="background"></div>
  <div class="container dashboard">
    <CreateDoc />
    <div v-for="doc in documents" :key="doc.id">
        <div class="card">
          <router-link :to="{ name: 'Document', params: { id: doc.id } }">
          <div class="card-heading">
            <h3 class="heading">{{ doc.title }}</h3>
            <div class="button-container">
              <button v-if="!doc.pinned" class="formatting-button outlined" @click.prevent="togglePin(doc.id)">
                <span class="material-icons-outlined">
                  push_pin
                </span>
              </button>
              <button v-if="doc.pinned" class="formatting-button outlined" @click.prevent="togglePin(doc.id)">
                <span class="material-icons pinned">
                  push_pin
                </span>
              </button>
              <div class="dropdown">
                <button class="formatting-button outlined" @click.prevent="toggleMenu(doc.id)">
                  <span class="material-icons-outlined">
                    expand_more
                  </span>
                </button>
                <div class="dropdown_item-container">
                  <p class="dropdown_item" @click.stop="something">Edit Tags</p>
                  <p @click.stop="something">Delete</p>
                </div>
              </div>
            </div>
          </div>
          <p class="card-content">{{ doc.content }}</p>
          <button class="btn_tag" v-for="tag in doc.tags" @click.prevent="deleteTag(doc.id, tag)">{{ tag }}</button>
          <button class="btn_tag--add" @click.prevent="">+
            <form class="form--addTag" @submit.prevent="addTag(doc.id)">
              <div class="form--addTag">
                <input type="text" class="input--tag" v-model="newTag" />
              </div>
            </form>
          </button>
        </router-link>
        </div> 
    </div>
  </div>
</template>

<script>
import CreateDoc from '../components/Dashboard/CreateDoc.vue'
import { onBeforeMount, ref } from 'vue'
import { mapGetters } from 'vuex'
import { store } from '../store/index'
import { auth, documentsCollection } from '../firebase/config'
import firebase from 'firebase/app'
    

export default {
  name: 'Dashboard',
  components: { CreateDoc },
  computed: {
    ...mapGetters(['documents', 'user'])
  },
  setup() {

    onBeforeMount(() => {
        getUser()     
        getDocuments()
    })

    const newTag = ref('')

    const addTag = (id) => {
      var documentRef = documentsCollection.doc(id)

      if(newTag.value) {
        documentRef.update({
          tags: firebase.firestore.FieldValue.arrayUnion(newTag.value)
        })
      } else {
        return
      }
      newTag.value = ''
    }

    const deleteTag = (id, tag) => {
      var documentRef = documentsCollection.doc(id)

      documentRef.update({
        tags: firebase.firestore.FieldValue.arrayRemove(tag)
      })
    }

    const something = () => {
      return
    }

    const toggleMenu = (id) => {
      var documentRef = documentsCollection.doc(id)
      var document = store.getters.document(id)

      if (document.showMenu) { 
        documentRef.update({showMenu: false})
      } else { 
        documentRef.update({showMenu: true})
      }      
    }

    const togglePin = (id) => {
      var documentRef = documentsCollection.doc(id)
      var document = store.getters.document(id)

      if (document.pinned === false) {
        documentRef.update({pinned: true})
      } else {
          documentRef.update({pinned: false})
      }
    }

    const getUser = () => {
      // Get currentUser from Firebase auth
      const userID = auth.currentUser.uid

      // Set userID when coming to Dashboard so if the user reloads the store data is repopulated with documents data
      store.dispatch('setUser', userID)
    }
    const getDocuments = () => {
      // Bind to Firebase snapshots by calling Vuex action
      store.dispatch('bindDocuments', store.state.user)
    }
    return {  getUser, getDocuments, toggleMenu, togglePin, newTag, addTag, deleteTag }
  }
}
</script>

<style>

</style>
