<template>
  <div>
    <div class="container">
      <h3 class="tiptap-heading">Try tweaking some text by selecting it below</h3>
    </div>
    
    <div v-if="editor">
      <div class="tiptap-button-container">
        <div class="container">
          <button class="formatting-button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            <span class="material-icons-outlined editor-icon">
              format_bold
            </span>
          </button>
          <button class="formatting-button" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            <span class="material-icons-outlined editor-icon">
              format_italic
            </span>
          </button>
          <button class="formatting-button" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            <span class="material-icons-outlined editor-icon">
              format_strikethrough
            </span>
          </button>
          
          <button class="formatting-button" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
            <span class="material-icons-outlined editor-icon">
              code
            </span>
          </button>
          <button class="formatting-button" @click="editor.chain().focus().undo().run()">
            <span class="material-icons-outlined editor-icon">
              undo
            </span>
          </button>
          <button class="formatting-button" @click="editor.chain().focus().redo().run()">
            <span class="material-icons-outlined editor-icon">
              redo
            </span>
          </button>
          <button class="formatting-button" @click="editor.chain().focus().unsetAllMarks().run()">
            <span class="material-icons-outlined editor-icon">
              format_clear
            </span>
          </button>
        </div>
      </div>
    </div>
    <bubble-menu
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    >
      <button class="tweak-it-button" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        Tweak it
      </button>
    </bubble-menu>
    <div class="editorDiv" >
        <editor-content :editor="editor"/>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import vClickOutside from 'click-outside-vue3'
import { onMounted, ref, watchEffect, onUpdated, onBeforeUnmount } from 'vue'

export default {
  components: {
    EditorContent,
    BubbleMenu 
  },
  directives: {
      clickOutside: vClickOutside.directive
    },

  data() {
    return {
      editor: null,
      title: 'title',
      content:  `<h1>
          An example that changes out with the prior choice
        </h1>
        <p>
          Hi Jessica,
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et mollis erat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis et felis eu urna sollicitudin pretium sed nec ipsum. Cras placerat dignissim ante, eget rutrum elit aliquet at. Pellentesque aliquam placerat dolor eget rhoncus. Nullam facilisis massa quis arcu porta fringilla. Nullam est justo, pretium eu scelerisque vitae, aliquet vel orci. 
        </p>
        <p>
          Nunc vitae metus vulputate, pulvinar arcu quis, viverra augue. Sed tincidunt, mauris non commodo ultrices, ex felis faucibus dui, id consectetur ipsum purus sed massa. 
        </p>
        <p>
          Pellentesque in nisl nec tortor pharetra cursus id sit amet tellus. Nulla sagittis, felis ut dictum ultrices, dui mauris imperdiet lacus, nec dignissim erat eros nec elit. Mauris luctus urna non massa bibendum, eget auctor nisl ultricies. Suspendisse feugiat imperdiet justo. Nullam fermentum suscipit rhoncus.
        </p>
        <p>
          Best Regards,
        </p>
        <p>
          Tweak
        </p>
      `,
    }
  },

  mounted() {
    console.log()
    this.editor = new Editor({
      content: this.content,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style>
/* Basic editor styles */

</style>