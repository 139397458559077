<template>
    <div class="backdrop" @click.self="() => $emit('closeModal')"></div>
    <div class="form-container--login">
        <h1 class="form_heading">Log in</h1>
        <form class="login" @submit.prevent="handleSubmit">
            <input type="email" autocomplete="username" required class="input--modal" placeholder="email" v-model="email">
            <input type="password" autocomplete="current-password" required class="input--modal" placeholder="password" v-model="password">
            <div class="error">{{ error }}</div>
            <div class="multi-button-container center">
                <button class="nav-button--text cta">Submit</button>
            </div>
        </form>
    </div>
            
    
</template>

<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'

export default {
    emits: ['login'],
    setup(props, context) {
         const router = useRouter()

        //refs
        const email = ref('')
        const password = ref('')

        const { error, login } = useLogin()

        const handleSubmit = async() => {
            await login(email.value, password.value)
            if (!error.value) {
                context.emit('login') 
            }
        }
        
        return { email, password, handleSubmit, error }
    }
}
</script>

<style>

</style>